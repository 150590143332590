/* Full-width navbar with sticky positioning */
.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333 !important;
    color: white;
    position: sticky;
    top: 0;
    z-index: 10;
}

/* Container for the content inside the navbar */
.navbar-content {
    width: 100%;
    
    padding: 0.5rem 1in;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Style for the brand or title on the left */
.navbar-title {
    font-size: 1.5rem;
    margin: 0;
}

/* Container for the dropdowns aligned to the right */
.dropdowns-container {
    display: flex;
    gap: 10px; /* Small gap between the dropdowns */
}

/* Dropdown container */
.dropdown-container {
    position: relative;
}
  
  /* Dropdown button */
  .dropdown-button {
    background-color: #333;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
  }
  
  /* Dropdown content, hidden by default */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Display the dropdown on hover */
  .dropdown-container:hover .dropdown-content {
    display: block;
  }
  
  /* Individual dropdown items */
  .dropdown-item {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    
  }
  
  .dropdown-item:hover {
    background-color: #ddd;
  }
  .nested-dropdown-container {
    position: static;
  }
  .nested-dropdown-content {
    display: none;
    position: absolute;
    right: 100%;
    flex-direction: column; /* Stack the items vertically */
    align-items: flex-start; /* Align the items to the start of the container */
    gap: 10px; /* Add some space between the subpages */
    min-width: 160px; /* Match the width of the other dropdowns */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add the same shadow as the other dropdowns */
    background-color: white; /* Match the background color of the other dropdowns */
}

.dropdown-item:hover .nested-dropdown-content {
    display: block;
}



/*///////////////////////////////////////
/* Reuse styles from .navbar for .footer */
.footer {
  position: relative; /* Change from sticky to relative */
  bottom: 0;
  height: 80px;
  z-index: 5;
  background-color: #1b1a1a !important;

}

.footer-content {
  width: 100%;
  padding: 0.5rem 1.5in;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-title {
  font-size: 1.2rem; /* Adjust size as needed */
  color: white;

}

/* Style for footer links */
.footer-links {
  display: flex;
  gap: 10px; /* Small gap between the dropdowns */
}

.footer-link {
  color: white;
  text-decoration: none;
  margin: 0 10px; /* Space between links */
}

.footer-link:hover {
  text-decoration: underline; /* or any other hover effect */
}
