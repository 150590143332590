/* Add this CSS to your project's stylesheet */

/* Styles for the hero section */
.hero {
    position: relative; /* Needed for absolute positioning of the pseudo-element */
    text-align: center;
    padding: 100px 0;
    color: #1A1A1A;
}

.hero::before {
    content: ""; /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/public/mapback.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.55; /* Apply the opacity only to the background image */
    z-index: -1; /* Place it behind the text */
}

.hero-title {
    font-size: 3rem;
    margin-bottom: 20px;
}

.hero-subtitle {
    font-size: 1.5rem;
}

/* Styles for the main content */
.main-content {
    padding: 40px;
}

.section-title {
    font-size: 2rem;
    margin-bottom: 20px;
}

.section-text {
    font-size: 1.2rem;
    line-height: 1.6;
}

/* Styles for the 'Discover the Essence of History' section */
.about-section {
    background-color: #f8f8f8;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Styles for the 'Our Vision' section */
.vision-section {
    background-color: #fefefe;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Styles for the header title */
.header-title {
    font-size: 3rem;
    margin-bottom: 20px;
}

/* Additional styles for buttons, links, and other elements can be added as needed. */

/* Example button style */
.button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #0056b3;
}
