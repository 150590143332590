/* Timeline.css */
.timeline-container {
    white-space: nowrap;
    margin: 0 0in;
  }
  
  .timeline-item {
    display: inline-block;
    width: 100%; 
    border-radius: 4px;
    text-align: center;
  }
  
  .timeline-item-content {
    padding: 10px;
  }
  
  .timeline-item-text {
    font-weight: bold;
    display: block;
  }
  
  .timeline-item-duration,
  .timeline-item-dates {
    display: block;
    margin-top: 5px;
  }
  .timeline-iframe {
    width: 100%;
    height: 400px;
  }
